import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';

@Component({
  selector: 'cc-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class ChipComponent {
  @Input()
  leadingIcon: string | null = null;

  @Input()
  deletable = false;

  @Input()
  size: 'small' | 'default' = 'default';

  delete = output<void>();
}
